import { render, staticRenderFns } from "./applyCancelReturnRequestPopup.vue?vue&type=template&id=82bf8c2a&scoped=true&"
import script from "./applyCancelReturnRequestPopup.vue?vue&type=script&lang=babel&"
export * from "./applyCancelReturnRequestPopup.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82bf8c2a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
